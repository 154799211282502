/* You can add global styles to this file, and also import other style files */

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css";
@import "./assets/styles/style-norelian.css";
@import "./assets/styles/guesthub.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700;1,800&display=swap");

.arrow-rigth-icons {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3.5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-top: 5px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #999c9e;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  background-color: var(--gh-background);
}

.card {
  margin: 0px 0px 17px 0px;
  padding: 16px;
  min-height: 70px;
  background: var(--gh-color-white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 6px #00000014;
  border-radius: 4px;
  opacity: 1;
}


/* Set body settings */

body {
  background-color: var(--gh-background);
  font-family: var(--gh-font-family) !important;
}

/* Begin: Set Devextreme dialog settings */

.dx-popup-content {
  background-color: rgb(242, 242, 242);
}

.dx-overlay-shader {
  background-color: rgba(64, 64, 64, 0.8);
}

/* End: Set Devextreme dialog settings */

.dx-field-label {
  font-weight: bold;
}

.dx-field>.dx-field-label {
  width: 41%;
}

.dx-field>.dx-field-value {
  width: 59%;
}

.dx-field>.dx-field-value-static {
  width: 59%;
}

.followReqDetails {
  margin-left: 20px;
  margin-top: 15px;
}

.followReqDetails>.questionReqDetails {
  font-weight: bold;
}

.followReqDetails>.responseReqDetails {
  padding-left: 15px;
  padding-top: 3px;
}

.form-horizontal .control-label {
  text-align: left;
}

.navbar-static-side {
  z-index: 1001 !important;
  /*DHS - fix because this menu block the popups starting at 2001 for z-index values*/
}

.mini-navbar .configMenu.active {
  bottom: 210px !important;
}

.mini-navbar .configMenu .nav-second-level .fa {
  float: left !important;
}

.mini-navbar .configMenu .nav-second-level .nav-label {
  display: block !important;
}

.mini-navbar .nav-second-level {
  width: 190px !important;
}

.mini-navbar .nav-third-level {
  padding-left: 20px !important;
}

.mini-navbar .login-gh {
  display: none;
}

.login-gh-mini {
  display: none;
}

.mini-navbar .login-gh-mini {
  display: block !important;
  min-width: 50px;
  margin-left: 10px;
}

.new-req-form .dx-placeholder {
  top: -5px !important;
}

.dx-toolbar-after {
  padding-right: 0px;
}

.dx-toolbar-before {
  padding-left: 10px;
}

.dx-datagrid-header-panel {
  overflow: visible;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #686c6d;
  font-size: 16px;
}

.drop-down__icon {
  color: #686c6d;
  font-size: 20px;
  margin-right: 16px;
}

.drop-down__item {
  color: #686c6d;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #686c6d;
  font-size: 16px;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #686c6d;
  font-size: 16px;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #686c6d;
  font-size: 16px;
}

.input-field__label {
  font-size: 12px;
  color: #686c6d;
  font-weight: 500;
  text-transform: uppercase;
}

/* .button-cancel {
  background: #686C6D !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
  box-shadow: none;
    opacity: 1;
} */
.button-cancel {
  background: transparent !important;
  color: var(--gh-color-dark-grey, #686C6D) !important;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
  box-shadow: none;
  opacity: 1;
}

.button-save {
  margin-left: 14px !important;
  background: var(--gh-color-dynamic-1) 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
  box-shadow: 0px 2px 6px #0000003d;
  opacity: 1;
}

.button-delete {
  margin-left: 14px !important;
  background: #ff4855 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 500;
  text-transform: capitalize;
  box-shadow: 0px 2px 6px #0000003d;
  opacity: 1;
}

.color-succes {
  color: #1bb394 !important;
}

/*block of drawer*/

.mydrawer__base-hide {
  display: none;
  /* Hidden by default */
}

.mydrawer__base {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

.mydrawer {
  display: block !important;
  z-index: 100 !important;
  width: 40%;
  height: 100%;
  background: var(--gh-color-white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 6px #0000003d;
  opacity: 1;
  float: right;
}

.mydrawer__cont-close {
  text-align: right;
  padding: 16px 16px 0px 16px;
  height: 25px;
}

.mydrawer__icon-close {
  font-size: 24px !important;
  color: #999999;
  opacity: 1;
}

.mydrawer__cont-title {
  padding: 48px 42px 0px 40px;
}

.mydrawer__cont {
  padding: 24px 42px 0px 30px;
}

.mydrawer__title {
  color: var(--gh-color-letter);
  font-size: 24px;
  font-weight: 500;
}

/*DHS for the config menu to auto show in second level
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
  display: block !important;
}*/

/* media querys bootrpa*/

/* Custom, iPhone Retina */

@media only screen and (min-width: 310px) and (max-width: 320px) {

  /*.dx-toolbar .dx-texteditor {

width: 200px!important;
right: 5px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 210px !important;
    z-index: 10000;
  }
}

@media only screen and (min-width: 321px) and (max-width: 400px) {

  /*.dx-toolbar .dx-texteditor {

width: 280px!important;
right: 5px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 290px !important;
    z-index: 10000;
  }
}

/* Extra Small Devices, Phones */

@media only screen and (min-width: 410px) and (max-width: 760px) {

  /*.dx-toolbar .dx-texteditor {

width: 300px!important;
right: 10px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 315px !important;
    z-index: 10000;
  }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 761px) and (max-width: 900px) {

  /*.dx-toolbar .dx-texteditor {

width: 450px!important;
right: 5px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 460px !important;
    z-index: 10000;
  }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) and (max-width: 1023px) {

  /*.dx-toolbar .dx-texteditor {

width: 650px!important;
right: 10px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 670px !important;
    z-index: 10000;
  }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1024px) and (max-width: 1800px) {

  /*.dx-toolbar .dx-texteditor {

width: 800px!important;
right: 10px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 820px !important;
    z-index: 10000;
  }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1280px) and (max-width: 1389px) {

  /*.dx-toolbar .dx-texteditor {

width: 980px!important;
right: 10px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 1000px !important;
    z-index: 10000;
  }

  .content::-webkit-scrollbar {
    /* solo oculta su visualizacion */
    display: none;
  }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1390px) and (max-width: 1800px) {

  /*.dx-toolbar .dx-texteditor {

width: 1000px!important;
right: 10px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 1020px !important;
    z-index: 10000;
  }
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1900px) and (max-width: 2600px) {

  /*.dx-toolbar .dx-texteditor {

width: 1550px!important;
right: 10px !important;
}*/
  #btnr {
    position: absolute !important;
    top: 13%;
    right: 1570px !important;
    z-index: 10000;
  }
}

.imprim {
  display: none;
}

@media print {
  #imprimeme {
    background: red;
    color: white;
  }

  .noimpr {
    display: none;
  }

  .imprim {
    display: block;
  }
}

.dash_template {
  background-color: rgb(242, 242, 242) !important;
}

span,
a,
b,
p,
u {
  color: var(--gh-color-letter);
}



/*chec*/

.toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9a9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}

.toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}

.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: var(--gh-color-dynamic-1);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

#cbx:checked+.toggle:before {
  background: var(--gh-color-dynamic-1);
}

#cbx:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#cbx:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#Request:checked+.toggle:before {
  background: #5fe6cb;
}

#Request:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#Request:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#Pickup:checked+.toggle:before {
  background: #5fe6cb;
}

#Pickup:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#Pickup:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#Communications:checked+.toggle:before {
  background: #5fe6cb;
}

#Communications:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#Communications:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#FoodBeverage:checked+.toggle:before {
  background: #5fe6cb;
}

#FoodBeverage:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#FoodBeverage:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#Guest_items:checked+.toggle:before {
  background: #5fe6cb;
}

#Guest_items:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#Guest_items:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#cbxtransport:checked+.toggle:before {
  background: var(--gh-color-dynamic-1);
}

#cbxtransport:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#cbxtransport:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#sug:checked+.toggle:before {
  background: var(--gh-color-dynamic-1);
}

#sug:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#sug:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#dayw:checked+.toggle:before {
  background: var(--gh-color-dynamic-1);
}

#dayw:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#dayw:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

#chwho:checked+.toggle:before {
  background: var(--gh-color-dynamic-1);
}

#chwho:checked+.toggle span {
  background: var(--gh-color-dynamic-1);
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
}

#chwho:checked+.toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.chulito {
  float: right;
  margin-top: -3px;
  padding-left: 15px;
  cursor: pointer;
}

.add {
  float: right;
}

.delete {
  float: right;
  margin-left: 5px;
}

.nopPading-bottom {
  padding: 0px;
  padding-bottom: 10px;
}

/* check*/

.btnRefresh {
  border-color: var(--gh-color-dynamic-1) !important;
  color: var(--gh-color-dynamic-1) !important;
}

.btnRefresh>i {
  color: var(--gh-color-dynamic-1) !important;
}

.input-txt {
  border: none !important;
  border-bottom: 1px solid !important;
}

.input-txt:focus {
  outline: none;
  border-bottom: 2px solid var(--gh-color-dynamic-1) !important;
}

.dx-texteditor.dx-editor-outlined {
  border-radius: 0px !important;
  border: none !important;
}

.dx-texteditor.dx-editor-outlined:focus {
  border-radius: 0px !important;
  outline: none;
}

.dx-texteditor.dx-editor-outlined:active {
  border-radius: 0px !important;
  outline: none;
}

.dx-texteditor.dx-editor-outlined:hover {
  border-radius: 0px !important;
  outline: none;
}

#test ::ng-deep .mat-form-field-appearance-outline {
  width: 65px;
  height: 45px;
  line-height: 1.725;
  color: red;
}

#test ::ng-deep .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0px 10px 0px 10px;
}

#test ::ng-deep .mat-form-field-infix {
  padding: 3px 0 3px 0;
}

#test ::ng-deep .mat-form-field-hide-placeholder .mat-form-field-label-wrapper {
  text-align: center;
  top: -25px;
}

#test ::ng-deep .mat-form-field-should-float {
  text-align: center;
}

.padding0 {
  padding: 0px !important;
}

/**
Apss webs

*/

.modal-overlay {
  background: #0000007a !important;
}

.up {
  display: block !important;
  z-index: 1003 !important;
  width: 400px;
  max-height: 85% !important;
  top: 50% !important;
  transform: translateY(-50%);
}

.modal_right_0 {
  display: none;
  position: fixed;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 100%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  border-radius: 2px;
  will-change: top, opacity;
}

.up_right {
  display: block !important;
  z-index: 100 !important;
  top: 0px !important;
  /* transform: scaleX(1) scaleY(1); */
  width: 530px;
  min-height: 100% !important;
  height: auto;
  overflow: hidden;
}

.ups {
  z-index: 990 !important;
  display: block !important;
  opacity: 0.5 !important;
}

.up_modal {
  display: block !important;
  z-index: 1003 !important;
  width: 400px;

}

.ups_modal {
  z-index: 1002 !important;
  display: block !important;
  margin-top: -25vh !important;
}

.ups_modal_guest {
  z-index: 1002 !important;
  display: block !important;
  margin-top: -17vh !important;
}

.ups_modal_profile {
  z-index: 1002 !important;
  display: block !important;
  margin-top: -12vh !important;
}

.up_right_guest_app {
  display: block !important;
  z-index: 1000 !important;
  width: 400px;
  top: 0px !important;
  transform: scaleX(1) scaleY(1);
}

.ups_guest_app {
  z-index: 999 !important;
  display: block !important;
  opacity: 0.5 !important;
}

.downs {
  display: none !important;
}

.btn,
.btn-large,
.btn-small {
  background-color: none !important;
}

.btn-flat {
  background-color: transparent;
  border: solid 1px var(--gh-color-dynamic-1) !important;
  color: var(--gh-color-dynamic-1) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-flat:hover,
.btn-flat-ant:hover {
  background-color: none !important;
}

.btn-flat-ant {
  background-color: transparent;
  color: var(--gh-color-dynamic-1) !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.button-options {
  background: #c6c3bd !important;
}

.button-options.active {
  background: var(--gh-color-dynamic-1) !important;
  color: #fff !important;
}

.btn,
.btn-large,
.btn-small,
.btn-flat {
  padding: 0 10px !important;
  border-radius: 4px !important;
}

/* .btn:hover,
.btn-large:hover,
.btn-small:hover {
  border-bottom: 2px solid var(--gh-color-dynamic-1);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
} */

h1 {
  font-size: 3.02rem !important;
  color: #999c9e;
}





.btn-group {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group>.btn-inactive {
  background-color: #607d8b;
}

.btn-group>.btn {
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0),
    0 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0px rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0);
}

.btn-group>.btn-inactive:hover {
  background-color: #728f9d;
}

.btn-group>.btn:hover {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 2.56rem !important;
  color: #999c9e;
}

h3 {
  font-size: 1.28rem !important;
  color: #999c9e;
}

h4 {
  font-size: 1.17rem !important;
  color: #999c9e;
}

.btn-small-s {
  padding: 0 10px !important;
  height: 25px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  line-height: 25px !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}

.btn-small-xs {
  padding: 0 10px !important;
  height: 15px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  line-height: 15px !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  line-height: 1.33;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 50px !important;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

h5 {
  font-size: 0.83rem !important;
}



h6 {
  font-size: 0.6rem !important;
}



/* strong, */

/* b { */

/* font-weight: bold !important; */

/* } */

.input-field>label:not(.label-icon).active {
  -webkit-transform: translateY(-14px) scale(0.8) !important;
  transform: translateY(-14px) scale(0.8) !important;
  -webkit-transform-origin: 0 0 !important;
  transform-origin: 0 0 !important;
}

.justify-tex {
  text-justify: auto !important;
}

.margin-top0 {
  margin-top: 0px !important;
}

.margin12 {
  margin-top: -12px !important;
}

.margin0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.margin2 {
  margin-top: 4px !important;
}

.margin-rigth5 {
  margin-right: 14px !important;
}

.margin-rigth10px {
  margin-right: 10px !important;
}

.margin-rigth15 {
  margin-right: 15px !important;
}

.margin-rigth25 {
  margin-right: 25px !important;
}

.margin-left15 {
  margin-left: 15px !important;
}

.margin-top-25 {
  margin-top: -25px !important;
}

.margin-top-15 {
  margin-top: -15px !important;
}

.margin-top-12 {
  margin-top: -12px !important;
}

.margin-top15 {
  margin-top: 15px !important;
}

.margin-top10 {
  margin-top: 10px !important;
}

.margin-bottom15 {
  margin-bottom: 15px !important;
}

.margin-bottom10 {
  margin-bottom: 10px !important;
}

.border-default {
  border-bottom: solid 1px #9e9e9e !important;
}

hr {
  border: 1px solid #9e9e9e;
}

a {
  cursor: pointer !important;
}

.padding-left-ringth15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.padding-ringht0 {
  padding-right: 0px !important;
}

.dx-datebox-wrapper .dx-popup-title {
  border-bottom: none !important;
}

.font-bold {
  font-weight: 500;
}

.control-label {
  padding-top: 15px !important;
}

.dx-lookup-field {
  border-bottom: solid 1px rgba(0, 0, 0, 0.42) !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dx-placeholder:before {
  padding: 0px !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: var(--gh-color-dynamic-1) !important;
}

.col .row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.dx-texteditor-input {
  height: 0px !important;
}

.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
  background: transparent !important;
  border-color: transparent !important;
}

.dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon {
  background: transparent !important;
  border-color: transparent !important;
}

.padding-left-ringth0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.padding-top15 {
  padding-top: 15px !important;
}

.padding-bottom15 {
  padding-bottom: 15px !important;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.inputt .dx-placeholder {
  padding-left: 0px !important;
  font-size: 14px !important;
}

.mat-button {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-danger {
  color: #ef5352;
}

.text-warning {
  color: #f8ac59;
}

.text-title-create-request {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px !important;
  text-align: right !important;
}

textComment>.dx-texteditor.dx-editor-outlined {
  border: 1px solid #ddd !important;
}

.btn-group {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group>.btn-inactive {
  background-color: #607d8b;
}

.btn-group>.btn {
  -webkit-box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0),
    0 0px 0px 0px rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0px rgba(0, 0, 0, 0),
    0 0px 0px 0 rgba(0, 0, 0, 0);
}

.btn-group>.btn-inactive:hover {
  background-color: #728f9d;
}

.btn-group>.btn:hover {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.who {
  background-color: #1a7bb9 !important;
  border-color: #1a7bb9 !important;
}

.where {
  background-color: #21b9bb !important;
  border-color: #21b9bb !important;
}

.what {
  background-color: #f7a54a !important;
  border-color: #f7a54a !important;
}

.when {
  background-color: #ed5565 !important;
  border-color: #ed5565 !important;
}

.contentCard {
  padding-top: 20px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 20px !important;
}

.contendAction {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 5px !important;
  padding-left: 0px !important;
  background: #ddd !important;
}

.btn-default {
  background-color: #686C6D !important;
  color: white !important;
}

.btn-default:active {
  background-color: var(--gh-color-dynamic-1) !important;
  color: white !important;
}

.padding5 {
  padding: 5px !important;
}

.padding-top5 {
  padding-top: 5px !important;
}

.padding15 {
  padding: 15px !important;
}

.padding16 {
  padding: 16px !important;
}

.dx-searchbox .dx-icon-search {
  display: none !important;
}

.sortable-handler {
  touch-action: none;
}

.priority-highest {
  color: #ff0700 !important;
}

.priority-high {
  color: #ff9f00 !important;
}

.priority-medium {
  color: #f8e71c !important;
}

.priority-low {
  color: #0675d8 !important;
}

.priority-lowest {
  color: #73008b !important;
}

.btn-floating {
  display: inline-block !important;
  color: #fff;
  position: relative !important;
  overflow: hidden !important;
  z-index: 1 !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 !important;
  background-color: var(--gh-color-dynamic-1) !important;
  border-radius: 50% !important;
  -webkit-transition: background-color 0.3s !important;
  transition: background-color 0.3s !important;
  cursor: pointer !important;
  vertical-align: middle !important;
}

.color-default {
  color: #686c6d !important;
  font-size: 16px !important;
}

.mat-option-text {
  color: var(--gh-color-letter);
  font-family: var(--gh-font-family) !important;
}

.mat-select-value-text {
  color: #999c9e !important;
  font-family: var(--gh-font-family) !important;
}

input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  font-size: 16px !important;
  color: var(--gh-color-letter) !important;
}

.center,
.center-align {
  text-align: center !important;
}

.margin5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.dx-checkbox-checked .dx-checkbox-icon {
  color: white !important;
  border: 2px solid var(--gh-color-dynamic-1) !important;
  background-color: var(--gh-color-dynamic-1) !important;
  font-size: 12px !important;
  border-radius: 4px !important;
}

.btn-danger {
  background-color: #ed5565;
  border-color: #ed5565;
  color: #ffffff;
}

.btn-danger:hover {
  background-color: #ed5565;
  color: #ffffff;
}

.dx-popup-wrapper>.dx-overlay-content {
  border: none !important;
  border-radius: none !important;
}

.padding10 {
  padding: 10px !important;
}

.title-style {
  font-size: 1rem !important;
}

i.tiny {
  font-size: 1rem !important;
}

i.tiny-s {
  font-size: 0.5rem !important;
}

i.small {
  font-size: 1rem !important;
}

i.medium {
  font-size: 4rem !important;
}

i.large {
  font-size: 6rem !important;
}

.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
  background-color: transparent !important;
  border-color: transparent;
  color: #999c9e !important;
  opacity: 1;
}

.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
  background-color: transparent !important;
  border-color: transparent;
  color: #999c9e !important;
  opacity: 1;
}

.dx-tab-selected:after {
  border-right: 0px !important;
  border-left: 0px !important;
}

.dx-tab.dx-tab-selected {
  background-color: var(--gh-color-dynamic-1)1f !important;
  border-bottom: 2px solid var(--gh-color-dynamic-1) !important;
}

.dx-tabpanel .dx-tab-selected:before {
  border-bottom: 0px !important;
}

.dx-tabpanel .dx-state-focused .dx-tab-selected:after {
  border: 0px !important;
}

.text-info {
  color: #23c6c8;
}

.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all 0.5s;
  border: 1px solid #c2c2c2;
}

.btn-info {
  background-color: var(--gh-color-dynamic-1);
  /* border-color: #23c6c8; */
  color: #ffffff;
  box-shadow: none !important;
}

.btn-infoStaff {
  background-color: #f4f4f4;
  /* border-color: #23c6c8; */
  color: #686C6D;
  box-shadow: none !important;
}

.btn-warning {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: #ffffff;
}

.navy,
.navy-bg,
.bg-primary {
  background-color: var(--gh-color-dynamic-1) !important;
  color: #ffffff !important;
}

.btn-margin-right5 {
  margin-right: 5px !important;
}

.btn-margin-left5 {
  margin-left: 5px !important;
}

.paddin-right-left5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.stripedd-list:nth-child(even) {
  background-color: rgba(242, 242, 242, 0.5) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.table-color-title {
  background-color: rgba(242, 242, 242, 0.5) !important;
}

.mat-select-value {
  padding-bottom: 10px !important;
}

.pointer {
  cursor: pointer;
}

.icon-search {
  position: absolute !important;
}

.icon-search-hidden {
  display: none !important;
}

.icon-search-visible {
  display: block !important;
}

.mat-standard-chip {
  min-height: 25px !important;
  height: 100% !important;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
  top: none !important;
  left: none !important;
  right: none !important;
  bottom: none !important;
  position: static !important;
  pointer-events: none !important;
  border-radius: none !important;
}

.margin-top-botton5 {
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.activeTab {
  background-color: var(--gh-color-dynamic-1)1f !important;
  border-bottom: 2px solid var(--gh-color-dynamic-1) !important;
}

.margin-left-right0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tabs .tab a.active,
.tabs .tab a:hover {
  color: var(--gh-color-dynamic-1) !important;
}

/*.dx-widget, .dx-widget:before, .dx-widget:after, .dx-widget *, .dx-widget *:before, .dx-widget *:after{

    color: #999c9e ;


}*/

.dx-datagrid-content .dx-datagrid-table .dx-row>td {
  font-size: 14px;
}

/**
menu --
*/

.header-style {
  background-color: #34495e;
}

nav ul li {
  font-size: 14px !important;
}

nav ul a {
  font-size: 14px !important;
}

nav ul li.active {
  /*background-color: #293846 !important;*/
  border-left: 3px solid var(--gh-color-dynamic-1) !important;
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li.active a {
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li.active span {
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li a .active {
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li a span {
  margin-left: 10px !important;
}

nav ul li a .active span {
  color: var(--gh-color-dynamic-1) !important;
  margin-left: 8px !important;
}

nav ul li .active3 {
  /*background-color: #293846 !important;*/
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li a .active3 {
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li a .active3 i {
  color: var(--gh-color-dynamic-1) !important;
}

nav ul a:hover {
  /* background-color: #293846 !important;
 /*border-bottom: 2px solid var(--gh-color-dynamic-1) !important;*/
  color: var(--gh-color-dynamic-1);
}

#li-Menu {
  height: 40px;
  /*border-left: 3px solid transparent !important;*/
}

#li-Menu ul li:hover {
  /*background-color: #293846 !important;*/
  /*border-bottom: 2px solid var(--gh-color-dynamic-1) !important;*/
  color: var(--gh-color-dynamic-1);
}

nav ul li .active2 {
  /*background-color: #293846 !important;*/
  /*  border-bottom: 2px solid var(--gh-color-dynamic-1) !important;*/
  color: var(--gh-color-dynamic-1) !important;
}

nav ul li .active2 a {
  color: var(--gh-color-dynamic-1) !important;
  /**padding-left: 5px !important;**/
}

nav ul li .active2 i {
  color: var(--gh-color-dynamic-1) !important;
  /**padding-left: 5px !important;**/
}

.active2 {
  color: var(--gh-color-dynamic-1) !important;
}

.active2>a>span {
  color: var(--gh-color-dynamic-1) !important;
}

.active2>a>p {
  color: var(--gh-color-dynamic-1) !important;
}

.active2>a {
  color: var(--gh-color-dynamic-1) !important;
}

.active3_base>a {
  color: var(--gh-color-dynamic-1) !important;
}

.active3_base>a>i {
  color: var(--gh-color-dynamic-1) !important;
}

.active3_base>a>span {
  color: var(--gh-color-dynamic-1) !important;
}

.noactive {
  color: var(--gh-color-grey) !important;
}

.menu_an {
  background: #34495e !important;
  padding: 0px !important;
}

.active_menu {
  /*background-color: #293846 !important;*/
  border: none !important;
  border-left: 3px solid var(--gh-color-dynamic-1) !important;
  color: var(--gh-color-letter) !important;
  height: auto !important;
}

.active_menu>a>i {
  color: var(--gh-color-dynamic-1) !important;
}

.active_menu>a>img {
  color: var(--gh-color-dynamic-1) !important;
}

.active_menu>a>span {
  color: var(--gh-color-dynamic-1) !important;
}

td {
  color: #999c9e;
}

.menu_hover:hover {
  background-color: #293846 !important;
}

.mat-menu-panel {
  border-radius: 0px !important;
  min-width: 280px;
  max-width: 320px !important;
}

.mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.float_menu {
  font-size: 13px !important;
  width: 200px !important;
  position: absolute !important;
  z-index: 200 !important;
  left: 3.4% !important;
  background: #f4f4f4 !important;
  margin-top: -4% !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.dx-datagrid-content .dx-datagrid-table .dx-row>td {
  font-size: 14px !important;
}

.dx-checkbox-icon {
  color: white !important;
}

.dx-checkbox-checked .dx-checkbox-icon:before {
  color: white !important;
}

.inputangmate {
  border-bottom: none !important;
}

.inputangmate:focus {
  border-bottom: none !important;
  box-shadow: none !important;
}

.inputangmate:active {
  border-bottom: none !important;
  box-shadow: none !important;
}

.borderAll {
  border: 1px solid #999c9e !important;
}

.btn-margin-top5 {
  margin-top: 5px !important;
}

.bold {
  color: #222d32 !important;
}

.dx-datagrid-headers {
  color: #222d32 !important;
}

.dx-datagrid-headers>td {
  color: #222d32 !important;
}

.dx-header-row .dx-text-content-alignment-left,
.dx-header-row .dx-text-content-alignment-right {
  color: #222d32 !important;
}

.dx-checkbox-icon {
  width: 22px !important;
  height: 22px !important;
  border-radius: 2px !important;
  border: 1px solid #ddd !important;
  background-color: #fff !important;
}

.dx-treeview .dx-treeview-node.dx-treeview-item-with-checkbox .dx-treeview-item {
  color: #686c6d !important;
  padding-left: 36px !important;
}

.dx-treeview-item {
  padding: 0px 6px !important;
}

.dx-treeview-toggle-item-visibility:before {
  margin-top: -15px !important;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: none !important;
}

.btn-small-s {
  padding: 0 10px !important;
  height: 25px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  line-height: 25px !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}

.btn-small-xs {
  padding: 0 10px !important;
  height: 15px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  line-height: 15px !important;
  font-size: 13px !important;
  border-radius: 4px !important;
}

.ngx-pagination .current {
  background: #999c9e !important;
  color: white !important;
}

.ngx-pagination .current>span {
  color: white !important;
}

.select_bottom {
  border: none !important;
  border-bottom: 1px solid #f2f2f2 !important;
  color: #999c9e !important;
}

.text-icon {
  color: var(--gh-color-dynamic-1) !important;
}

.font-small {
  font-size: 11px !important;
}

.grey-1 {
  background: #d2d2d2 !important;
}

@media only screen and (min-width: 1100px) {
  .center-center {
    text-align: right !important;
  }

  .total-box {
    height: 180px;
    padding: 15px;
    position: relative;
    white-space: normal;
    width: 14% !important;
  }
}

@media only screen and (max-width: 1099px) {
  .center-center {
    text-align: center !important;
  }

  .total-box {
    height: 180px;
    padding: 15px;
    position: relative;
    white-space: normal;
    padding: 10px;
    margin-top: 5px;
    /*width: 14% !important;*/
  }
}

@media only screen and (max-width: 700px) {
  .margin-top-padding-left {
    margin-top: 15px;
    padding-left: 0px !important;
  }
}

@media (max-width: 768px) {
  /**.settingsD {
display: none !important;
}*/
}

.generalColorNew {
  color: #686c6d !important;
}

.checbron {
  color: #34495e !important;
  top: 8px !important;
  position: relative !important;
}

.padding5 {
  padding: 8px 5px !important;
}

.min-search {
  height: 200px !important;
  overflow: auto !important;
}

.checkSugges {
  position: relative;
  top: -5px;
}

.padding10 {
  padding: 10px !important;
}

.margin-top-5 {
  margin-top: -5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.nameenab {
  color: #676a6c;
  font-weight: 600;
  font-size: 13px;
  padding-top: 10px;
}

.border-radius4 {
  border-radius: 4px !important;
}

.margin-bottom5 {
  margin-bottom: 5px !important;
}

.h4 {
  font-size: 1.16rem !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom0 {
  margin-bottom: 0px !important;
}

.padding-left-rigth8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.padding-top-bottom15 {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.widthScrollSuggestedDiv {
  width: 240px !important;
  float: left;
}

.newWidth {
  width: 1400px !important;
}

.newSubcategory:target {
  width: 1000px;
}

.margin-left11 {
  margin-left: 11px !important;
}

.margin-left10 {
  margin-left: 10px !important;
}

.widthScrollSuggestedDivNew {
  width: 600px !important;
  float: left;
}

.positionClose {
  position: fixed !important;
  margin-left: -80px !important;
  margin-top: 15px !important;
}

.scroll-x {
  overflow-x: auto !important;
  min-width: 1000px;
}

.responsive-img {
  max-width: 100% !important;
  height: auto !important;
}

.padding-left0 {
  padding-left: 0px !important;
}

.padding-right0 {
  padding-right: 0px !important;
}

.mat-snack-bar-container {
  background: var(--gh-color-letter) !important;
  color: white !important;
  min-width: 100px !important;
}

.mat-simple-snackbar {
  flex-direction: row-reverse;
}

.mat-simple-snackbar>span {
  color: white !important;
  font-size: 15px !important;
}

.mat-simple-snackbar-action {
  margin: -8px -5px -8px -16px !important;
}

.mat-simple-snackbar-action>.mat-button {
  box-shadow: none !important;
}

.mat-simple-snackbar-action>.mat-button>.mat-button-wrapper {
  font-size: 22px !important;
}

.mat-simple-snackbar-action>.mat-button>.mat-button-wrapper {
  background-color: white;
  border-radius: 50%;
  font-size: 19px !important;
  padding: 0 6px !important;
}

.margin-rigth5 {
  margin-right: 5px !important;
}

.dx-dropdowneditor-button.dx-button-mode-outlined,
.dx-dropdowneditor-button.dx-button-mode-contained,
.dx-dropdowneditor-button.dx-button-mode-text {
  right: -10px !important;
}

.btn.disabled {
  color: #ffffff !important;
  background: #c3c3c3 !important;
}

.eye {
  position: absolute !important;
  padding-left: 10px !important;
  color: #999c9e !important;
}

.font12 {
  font-size: 12px !important;
}

.margin-bottom0 {
  margin-bottom: 0px !important;
}

.error {
  border-color: red !important;
}

.inDropDown {
  display: block;
  height: auto;
  max-height: 400px !important;
  min-width: 200px;
  scroll-behavior: smooth;
  height: auto;
  transform-origin: 0px 0px;
  opacity: 1;
  transform: scaleX(1) scaleY(1);
  position: relative;
  padding-bottom: 10px;
}

.inDropDown li {
  min-height: 35px !important;
}

.outDropDown {
  display: none;
}

.filled-out:before {
  top: -5px !important;
  left: -5px !important;
  width: 8px !important;
  height: 13px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: 2px solid #fff !important;
  border-bottom: none !important;
  -webkit-transform: rotateZ(90deg) !important;
  transform: rotateZ(90deg) !important;
  -webkit-transform-origin: 100% 100% !important;
  transform-origin: 100% 100% !important;
}

.iconInfo {
  margin-top: 20px !important;
  margin-left: -20px !important;
}

.icon-color {
  color: var(--gh-color-letter);
}

.container-fluid::-webkit-scrollbar {
  /* solo oculta su visualizacion */
  display: none;
}

@media (min-width: 902px) {
  .bodyScroll {
    overflow-y: hidden;
  }
}

@media (max-width: 900px) {
  .bodyScroll {
    overflow-y: auto;
  }
}

.margin-letf8 {
  margin-left: -8% !important;
}

.floatFooter {
  position: fixed;
  bottom: 10px;
}

.floatFooterName {
  color: #686c6d !important;
  font-size: 12px;
}

.activeSubmenu {
  color: var(--gh-color-dynamic-1) !important;
}

.padding30 {
  padding: 30px !important;
}

.padding30-50 {
  padding: 30px 50px !important;
}

.li-heigth>a {
  padding-left: 10px !important;
}

#li-Menu>a {
  padding-left: 10px !important;
}

#li-Menu3>a {
  padding-left: 10px !important;
}

.active_menu>a {
  padding-left: 6px !important;
}

.check-circe-20 {
  color: var(--gh-color-dynamic-1);
  font-size: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 0px;
}

#menuSide::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 0px;
}

#menuSide::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0px;
}

.padding32LeftRight {
  padding: 0px 32px 0px 32px !important;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent !important;
  border-radius: 100px !important;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #c3c3c3;
  border-radius: 100px;
}

.padding5right0left {
  padding: 0px 5px 0px 0px !important;
}

.icon-menu30 {
  font-size: 30px;
  color: var(--gh-color-light-grey-2);
}

pre {
  font-size: 16px;
  color: #676a6c;
}

::ng-deep .owl-dt-container-row:last-child {
  margin: 10px 0px !important;
}

.owl-dt-calendar-control {
  margin: 10px 0px !important;
}

.owl-dt-container-buttons {
  margin: 10px 0px !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: var(--gh-color-dynamic-1) !important;
  color: white !important;
}

.owl-dt-container-control-button:hover {
  background-color: var(--gh-color-dynamic-1) !important;
}

.owl-dt-container-control-button {
  margin-left: 8px !important;
  margin-right: 8px !important;
  border-radius: 3px !important;
}

.owl-dt-control-button-content:hover {
  color: white !important;
}

.printBlock {
  display: none;
}

#routeroutlet {
  -webkit-overflow-scrolling: touch !important;
}

.top32 {
  padding-top: 32px !important;
}

.bottom32 {
  padding-bottom: 32px !important;
}

.border-dashed {
  border: dashed 1px var(--gh-color-dynamic-1);
}

.padding-bottom12 {
  padding-bottom: 12px !important;
}

.semiblod {
  font-weight: 600 !important;
}

.blod {
  font-weight: bold !important;
}

.margin-top20 {
  margin-top: 20px !important;
}

.margin-top22 {
  margin-top: 22px !important;
}

.relative-3 {
  top: 3px !important;
  position: relative !important;
}

.margin-top5 {
  margin-top: 5px !important;
}

.margin-top8 {
  margin-top: 8px !important;
}

.margin-rigth30 {
  margin-right: 30px !important;
}

.margin-left30 {
  margin-left: 30px !important;
}

.padding16-32 {
  padding: 16px 32px !important;
}

.padding-left-ringth25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.margin-bottom3 {
  margin-bottom: 3px !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.margin-top50 {
  margin-top: 50px !important;
}

.padding32 {
  padding: 32px !important;
}

textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: 0;
  min-height: 35px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border .3s, -webkit-box-shadow .3s;
  transition: border .3s, -webkit-box-shadow .3s;
  transition: box-shadow .3s, border .3s;
  transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
  color: var(--gh-color-letter) !important;
}

textarea:focus {
  border-bottom: 1px solid var(--gh-color-dynamic-1);
  -webkit-box-shadow: 0 1px 0 0 var(--gh-color-dynamic-1);
  box-shadow: 0 1px 0 0 var(--gh-color-dynamic-1);
}

.margin-rigth45 {
  margin-right: 45px !important;
}

.green-text {
  color: var(--gh-color-dynamic-1) !important;
}

.margin-top25 {
  margin-top: 25px !important;
}

.titleTemplate {
  padding: 24px 0 !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  margin: 0px !important;
  line-height: 40px !important;
}

@media (max-width: 1400px) {
  .angular-editor-toolbar {
    height: 60px !important;
    padding: 0px !important;
  }
}

@media (min-width: 1402px) {
  .angular-editor-toolbar {
    height: 35px !important;
    padding: 0px !important;
  }
}

.icon-guests_16px:before {
  margin-left: -0px !important;
  margin-right: 0px !important;
}

.icon-communication_16px:before {
  margin: 0px !important;
  margin-right: 0px !important;
}

.icon-settings_16px:before {
  margin: 0px !important;
}

.icon-guest-_item_16px:before {
  margin: 0px !important;
}

.icon-pickup_16px:before {
  margin-left: 1px !important;
  margin-right: 0px !important;
}

.icon-fb_16px:before {
  margin-left: 1px !important;
  margin-right: 0px !important;
}

.icon-requests_16px:before {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.icon-tasks_16px:before {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.icon-dashboard_16px:before {
  margin-left: 1px !important;
  margin-right: 0px !important;
}

.note-form-label {
  color: var(--gh-color-letter) !important;
}

.note-modal-footer .note-btn {
  margin-top: -10px !important;
}

.note-btn-primary {
  background: var(--gh-color-dynamic-1) !important;
}

.note-btn-primary.focus,
.note-btn-primary:focus,
.note-btn-primary:hover {
  background-color: var(--gh-color-dynamic-1) !important;
}

.note-modal-content {
  position: relative;
  width: auto;
  margin: 30px 20px;
  border: 0px !important;
  background: #fff;
  background-clip: border-box;
  outline: 0;
  border-radius: 0px !important;
  box-shadow: 0 !important;
}

.note-image-btn {
  border: 0px !important;
}

.note-editor .note-toolbar .note-color-all .note-dropdown-menu,
.note-popover .popover-content .note-color-all .note-dropdown-menu {
  min-width: 350px !important;
}

.note-editor .note-toolbar .note-dropdown-menu,
.note-popover .popover-content .note-dropdown-menu {
  min-width: 225px !important;
}

.relative5 {
  top: 5px !important;
  position: relative !important;
}

.error_url {
  color: #f20000;
  padding-left: 3rem !important;
  font-size: 12px !important;
}

.background-base {
  background: var(--gh-color-white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 4px;
}

.padding-left-rigth4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.btn-margin-right10 {
  margin-right: 10px !important;
}

.aling-text-justify {
  text-align: justify !important;
}

.relative-5 {
  bottom: 7px !important;
  position: relative !important;
}

.margin-bottom30 {
  margin-bottom: 30px !important;
}

.note-editor.note-airframe .note-statusbar,
.note-editor.note-frame .note-statusbar {
  background-color: transparent !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top: 0 !important;
}

.btn-f-b {
  background: var(--gh-color-dynamic-1) !important;
}

.btn-f-b span {
  font-size: 1rem !important;
  line-height: initial !important;
  position: relative !important;
  top: -4px !important;
  color: white !important;
}

.btn-f-b i {
  color: white !important;
  position: relative !important;
  top: -1px !important;
}

.btn-f-b-flat {
  border: 1px solid var(--gh-color-dynamic-1);
}

.btn-f-b-flat #pending {
  font-size: 1rem !important;
  line-height: initial !important;
  position: relative !important;
  top: -6px !important;
  color: var(--gh-color-dynamic-1);
}

.btn-f-b-flat i {
  position: relative !important;
  top: -4px !important;
}

.border-solid-hr {
  border-top: 1px solid #e0e0e0;
}

.border-solid-bottom-hr {
  border-bottom: 1px solid #00000014;
}

.sub-text-base {
  color: #686c6d;
}

.border0 {
  border: 0px !important;
}

.padding-right5 {
  padding-right: 5px;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: #f4f4f4 !important;
}

.active_menu>a>.icon-foodandbeverage_16px:before {
  content: "\e81e";
  margin-left: -4.3px !important;
}

.icon-foodandbeverage_16px:before {
  content: "\e81e";
  margin-left: 2px !important;
}

.active_menu>a>.icon-guest-_item_16px:before {
  margin: 0px !important;
  margin-left: -3px !important;
}

.active_menu>a>.icon-pickup_16px:before {
  margin-left: -2px !important;
}

.active_menu>a>.icon-communication_16px:before {
  margin-left: -1.5px !important;
}

.active_menu>a>.icon-settings_16px:before {
  margin-left: -3px !important;
}

.active_menu>a>.icon-guests_16px:before {
  margin-left: -1px !important;
  margin-right: 0px !important;
}

.padding-right25 {
  padding-right: 25px !important;
}

.red-snackbar {
  color: #ffffff !important;
  background-color: var(--gh-color-letter) !important;
  margin-top: 60px !important;
}

.red-snackbar .mat-simple-snackbar-action>.mat-button>.mat-button-wrapper {
  color: #ff4855 !important;
}

.modal_left0 {
  left: none !important;
}

.guestMenuFilterBase {
  height: 270px !important;
  width: 300px !important;
}

.guestMenuFilterList {
  height: 200px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.guestMenuFilterCheck {
  height: 38px !important;
}

.guestMenuFilterActions {
  height: 50px !important;
  padding: 10px 15px !important;
}

.guestMenuFilterOk {
  margin-right: 10px !important;
}

.note-modal-backdrop {
  z-index: 999 !important;
  display: none !important;
}

.blod {
  font-weight: 700 !important;
}

.regular {
  font-weight: 400 !important;
}

.ligth {
  font-weight: 300 !important;
}

.color-base {
  color: var(--gh-color-letter) !important;
}

.color-base-two {
  color: #686c6d !important;
}

.font24 {
  font-size: 24px !important;
}

.font16 {
  font-size: 16px !important;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.border0 {
  border: 0px !important;
}

.padding-top10 {
  padding-top: 10px !important;
}

.padding-top8 {
  padding-top: 8px !important;
}

.cut-text {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--gh-font-family) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #9e9e9e !important;
}

.mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  color: var(--gh-color-dark-grey) !important;
  font-weight: 400;
}



.mat-menu-item {
  font-family: var(--gh-font-family) !important;
}

.imgEditor>img {
  width: 150px !important;
}

.mat-option {
  font-family: var(--gh-font-family) !important;
}

.mat-select-value-text {
  font-family: var(--gh-font-family) !important;
}

.mat-select-value-text>span {
  font-family: var(--gh-font-family) !important;
}

.mat-select-panel-wrap {
  flex-basis: 90% !important;
}

.mat-input-element {
  caret-color: #686c6d !important;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: #686c6d !important;
  opacity: 1 !important;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #eee;
  border: 1px solid var(--gh-color-dynamic-1) !important;
  color: var(--gh-color-dynamic-1) !important;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
  background-color: #fff !important;
  color: var(--gh-color-dynamic-1) !important;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
  background-color: var(--gh-color-dynamic-1) !important;
  color: white !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  color: var(--gh-color-dynamic-1) !important;
  font-weight: 600 !important;
}

.e-control,
.e-css {
  font-family: var(--gh-font-family) !important;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: var(--gh-color-dynamic-1) !important;
  color: white !important;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  border: 1px solid var(--gh-color-dynamic-1) !important;
  color: var(--gh-color-dynamic-1) !important;
}

.e-daterangepicker.e-popup .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets {
  color: var(--gh-color-letter) !important;
}

.e-calendar .e-content span,
.e-bigger.e-small .e-calendar .e-content span {
  color: var(--gh-color-letter) !important;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-title,
.e-daterangepicker.e-popup .e-calendar .e-header .e-title:hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-title,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-title:hover {
  color: var(--gh-color-letter) !important;
}

.e-daterangepicker.e-popup .e-start-label,
.e-daterangepicker.e-popup .e-end-label,
.e-bigger.e-small .e-daterangepicker.e-popup .e-start-label,
.e-bigger.e-small .e-daterangepicker.e-popup .e-end-label {
  color: var(--gh-color-letter) !important;
}

.e-daterangepicker.e-popup .e-day-span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-day-span {
  color: #686c6d !important;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
  color: var(--gh-color-letter) !important;
}

.mat-select {
  font-family: var(--gh-font-family) !important;
}

@media (min-width: 768px) {
  .note-modal-content {
    width: 530px !important;
    margin: 10px auto !important;
  }
}

.mat-form-field {
  font-family: var(--gh-font-family) !important;
}

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgb(0 0 0 / 0%), 0 1px 10px 0 rgb(0 0 0 / 8%),
    0 2px 4px -1px rgb(0 0 0 / 10%) !important;
}

.btn,
.btn-flat,
btn-large,
.btn-small {
  text-transform: Capitalize !important;
}

.btn-cancel-right {
  margin-top: 6px !important;
  margin-right: 15px !important;
  color: #686C6D !important;
}

.padding32-40 {
  padding: 32px 40px !important;
}

.padding-top16 {
  padding-top: 16px !important;
}

.margin0 {
  margin: 0px !important;
}

.card-base {
  background: var(--gh-color-white) 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  opacity: 1 !important;
}

.divider {
  height: 1px;
  background-color: #9e9e9e;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: rgb(242 242 242 / 8%) !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

@media (max-width: 800px) {

  #containerRouter {
    height: 94vh !important;
    background-color: white !important;
    overflow-x: hidden !important;
  }
}

#containerRouter {
  height: 94vh;
  overflow-x: hidden;
}

.mat-menu-panel {
  border-radius: 4px !important;
}

.mat-menu-panel {
  min-width: 112px;
  max-width: 280px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 4px;
  outline: 0;
  min-height: 48px !important;
}

.mat-elevation-z4 {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 12%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%) !important;
}

.inDropDownOne {
  max-height: 450px !important;
  border-radius: 8px 8px 0px 0px !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.inDropDownTwo {
  max-height: 450px !important;
  border-radius: 0px 0px 8px 8px !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

.divider-card {
  background-color: #686c6d26 !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: var(--gh-color-dynamic-1) !important;
}

.mat-slider-thumb-label {
  background-color: var(--gh-color-dynamic-1) !important;
}

.mat-slider-thumb {
  background-color: var(--gh-color-dynamic-1) !important;
}

.btn-return {
  margin-right: 10px;
}


.status-grey {
  background-color: #999999 !important;
  color: #FFFFFF !important;
  border: 1px solid #999999 !important;
}

.status-grey>span {
  color: #FFFFFF !important;
}

.status-yellow {
  background-color: #FFA64D !important;
  color: #FFFFFF !important;
  border: 1px solid #FFA64D !important;
}

.status-yellow>span {
  color: #FFFFFF !important;
}

.status-blue {
  background-color: #0084C4 !important;
  color: #FFFFFF !important;
  border: 1px solid #0084C4;
}

.status-blue>span {
  color: #FFFFFF !important;
}

.status-white {
  background-color: white !important;
  color: var(--gh-color-dynamic-1) !important;
  border: 1px solid var(--gh-color-dynamic-1);
  height: 25px !important;
  line-height: 24px !important;
}

.status-white>span {
  color: var(--gh-color-dynamic-1) !important;
}

.status-green {
  background-color: var(--gh-color-dynamic-1) !important;
  color: #FFFFFF !important;
}

.status-green>span {
  color: #FFFFFF !important;
}

.status-red,
.status-red:hover {
  background: var(--gh-color-white, white) !important;
  color: var(--gh-color-red, #FF4855) !important;
  border: 1px solid var(--gh-color-red, #FF4855) !important;
}

.status-red>span {
  color: var(--gh-color-red, #FF4855) !important;
}

.note-editing-area .note-codable {
  background-color: #FFFFFF !important;
  ;
}

.menuOptionPrime {
  width: 14rem !important;
  margin: 10px !important;
  box-shadow: none !important;
}

.menuOption {
  width: 12.5rem !important;
  margin: 10px !important;
}

.menuOptionTime {
  width: 15.2rem !important;
}

@media only screen and (max-width: 1220px) {
  .padding0-item-tab {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 1350px) {
  .padding0-item-pc {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 1440px) {
  .menuOptionPrime {
    width: 13rem !important;
    margin: 10px !important;
  }

  .menuOption {
    width: 11.5rem !important;
    margin: 10px !important;
  }

  .menuOptionTime {
    width: 14.2rem !important;
  }
}

@media only screen and (max-width: 900px) {
  .menuOptionPrime {
    width: 13rem !important;
    margin: 10px !important;
  }

  .menuOption {
    width: 10.5rem !important;
    margin: 10px !important;
  }

  .menuOptionTime {
    width: 13.2rem !important;
  }
}


app-web-editor-component span,
a,
b,
p,
u,
li,
ol,
section,
div {
  color: var(--gh-color-dark-blue, #2F4050);
}

app-web-editor-component blockquote {
  font-size: unset;
  color: unset
}

app-web-editor-component pre {
  font-size: unset;
  color: unset
}

app-web-editor-component h1 {
  font-size: unset;
  color: unset
}

app-web-editor-component h2 {
  font-size: unset;
  color: unset
}

app-web-editor-component h3 {
  font-size: unset;
  color: unset
}

app-web-editor-component h4 {
  font-size: unset;
  color: unset
}

app-web-editor-component h5 {
  font-size: unset;
}

app-web-editor-component h6 {
  font-size: unset;
}

.requeride {
  border-bottom: 1px solid var(--gh-color-red) !important;
  -webkit-box-shadow: 0 1px 0 0 var(--gh-color-red) !important;
  box-shadow: 0 1px 0 0 var(--gh-color-red) !important;
}

.helperText {
  color: var(--gh-color-red);
  font-size: 0.75rem !important;
}