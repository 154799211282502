@font-face {
  font-family: 'guesthub';
  src: url('../styles/font/guesthub.eot?37494643');
  src: url('../styles/font/guesthub.eot?37494643#iefix') format('embedded-opentype'),
       url('../styles/font/guesthub.woff2?37494643') format('woff2'),
       url('../styles/font/guesthub.woff?37494643') format('woff'),
       url('../styles/font/guesthub.ttf?37494643') format('truetype'),
       url('../styles/font/guesthub.svg?37494643#guesthub') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'guesthub';
    src: url('../font/guesthub.svg?37494643#guesthub') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "guesthub";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-quick_setup_16px:before { content: '\e800'; } /* '' */
.icon-request_set_up_16px:before { content: '\e801'; } /* '' */
.icon-email_templates_16px:before { content: '\e802'; } /* '' */
.icon-foun_16px:before { content: '\e803'; } /* '' */
.icon-general_16px:before { content: '\e804'; } /* '' */
.icon-guest-_item_16px:before { content: '\e805'; } /* '' */
.icon-guest_item_16px:before { content: '\e806'; } /* '' */
.icon-guests_16px:before { content: '\e807'; } /* '' */
.icon-integrations_16px:before { content: '\e808'; } /* '' */
.icon-lost_16px:before { content: '\e809'; } /* '' */
.icon-messages_16px:before { content: '\e80a'; } /* '' */
.icon-my_hub_16px:before { content: '\e80b'; } /* '' */
.icon-feature_16px:before { content: '\e80c'; } /* '' */
.icon-dashboard_16px:before { content: '\e80d'; } /* '' */
.icon-people_center_16px:before { content: '\e80e'; } /* '' */
.icon-communication_16px:before { content: '\e80f'; } /* '' */
.icon-preferences_16px:before { content: '\e810'; } /* '' */
.icon-reports_16px:before { content: '\e811'; } /* '' */
.icon-survey_16px:before { content: '\e812'; } /* '' */
.icon-requests_16px:before { content: '\e813'; } /* '' */
.icon-reservations_16px:before { content: '\e814'; } /* '' */
.icon-notification_16px:before { content: '\e815'; } /* '' */
.icon-settings_16px:before { content: '\e816'; } /* '' */
.icon-rules_16px:before { content: '\e817'; } /* '' */
.icon-statistics_16px:before { content: '\e818'; } /* '' */
.icon-tour_16px:before { content: '\e819'; } /* '' */
.icon-transports_16px:before { content: '\e81a'; } /* '' */
.icon-package_trackin_16px:before { content: '\e81b'; } /* '' */
.icon-guestapp_16px:before { content: '\e81c'; } /* '' */
.icon-pickup_16px:before { content: '\e81d'; } /* '' */
.icon-foodandbeverage_16px:before { content: '\e81e'; } /* '' */
.icon-service_16px:before { content: '\e81f'; } /* '' */
.icon-tasks_16px:before { content: '\e823'; } /* '' */
.icon-staff_hub_16px:before { content: '\e832'; } /* '' */
